@use 'sass:math';
@use 'typo';

$themes: (
  Marpple: (
    'black': #000,
    'dk-95': #28282f,
    'dk-80': #46464d,
    'white': #fff,
    'primary': #000,
    'secondary': #fff,
    'tertiary': #ff6b00,
    'success': #009444,
    'error': #ff6b00,
    'accent': #ff6b00,
    'gray-80': #858585,
    'gray-50': #a5a5a5,
    'gray-20': #d8d8d8,
    'gray-10': #f6f6f6,
    'gray-5': #fff,
    'gray-2-5': #fff,
    'dim-60': rgb(20 20 20 / 60%),
  ),
  MarppleShop: (
    'black': #141414,
    'dk-95': #28282f,
    'dk-80': #46464d,
    'white': #fff,
    'primary': #1c75ff,
    'secondary': #ebf3fe,
    'tertiary': #ebf3fe,
    'success': #009444,
    'error': #d92a0f,
    'accent': #1c75ff,
    'gray-80': #6b6b6b,
    'gray-50': #a2a2a2,
    'gray-20': #d9d9d9,
    'gray-10': #f5f5f5,
    'gray-5': #fff,
    'gray-2-5': #fff,
    'dim-60': rgb(20 20 20 / 60%),
  ),
  Ciety: (
    'black': #fbfbfb,
    'dk-95': #28282f,
    'dk-80': #46464d,
    'white': #13131a,
    'primary': #b2ff00,
    'secondary': #fbfbfb,
    'tertiary': #cdff8c,
    'success': #b2ff00,
    'error': #fa465a,
    'accent': #b2ff00,
    'gray-80': #b1b1b1,
    'gray-50': #77777c,
    'gray-20': #46464d,
    'gray-10': #323239,
    'gray-5': #28282f,
    'gray-2-5': #1c1c24,
    'dim-60': rgb(19 19 26 / 80%),
  ),
);

$devices: (
  pc: 'pc',
  mo: 'mo',
);

$langs: (
  kr: 'kr',
  en: 'en',
  jp: 'jp',
);

$black: var(--mauth-black);
$dk_95: var(--mauth-dk-95);
$dk_80: var(--mauth-dk-80);
$white: var(--mauth-white);
$primary: var(--mauth-primary);
$secondary: var(--mauth-secondary);
$tertiary: var(--mauth-tertiary);
$success: var(--mauth-success);
$error: var(--mauth-error);
$accent: var(--mauth-accent);
$gray_80: var(--mauth-gray-80);
$gray_50: var(--mauth-gray-50);
$gray_20: var(--mauth-gray-20);
$gray_10: var(--mauth-gray-10);
$gray_5: var(--mauth-gray-5);
$gray_2_5: var(--mauth-gray-2-5);
$dim_60: var(--mauth-dim-60);

$pc-max-width: 1920px;
$pc-layout-body-max-width: math.div(1440, typo.$font-size-pc) * 1rem;
$pc-layout-body-min-width: 1024px;
