@use '@/ui/style/sass';

/* 모든 요소에 대한 기본 스타일 초기화 */
*,
*::before,
*::after {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: inherit;
}

ul,
ol {
  list-style-position: inside; /* 번호를 텍스트 안쪽으로 정렬 */
}

table {
  border-spacing: 0;
  border-collapse: collapse;
}

/* 버튼 요소 */
button {
  border: none; /* 테두리 없음 */
  background-color: transparent; /* 배경색 투명 */
  color: inherit; /* 부모 요소의 텍스트 색상 상속 */
  font-size: inherit; /* 부모 요소의 글꼴 크기 상속 */
  line-height: inherit; /* 부모 요소의 줄 간격 상속 */
  cursor: pointer; /* 마우스 커서를 포인터로 변경 */
  appearance: none; /* 버튼의 기본 스타일 제거 */
}

/* 링크 요소 */
a {
  color: inherit; /* 부모 요소의 텍스트 색상 상속 */
  line-height: inherit; /* 초기값으로 되돌리기 */
  text-decoration: none; /* 밑줄 제거 */
  cursor: pointer; /* 마우스 커서를 포인터로 변경 */
}

/* 텍스트 입력 필드 */
input,
textarea {
  padding: 0; /* 내부 여백 */
  border: none; /* 테두리 스타일 */
  color: inherit; /* 부모 요소의 텍스트 색상 상속 */
  font-size: inherit; /* 부모 요소의 글꼴 크기 상속 */
  line-height: inherit; /* 부모 요소의 줄 간격 상속 */
  appearance: none; /* 기본 스타일 제거 */
}

/* 선택된 텍스트 입력 필드 */
input[type='text']:focus,
input[type='password']:focus,
input[type='email']:focus,
input[type='tel']:focus,
input[type='url']:focus,
input[type='number']:focus,
button:focus,
textarea:focus {
  border-color: unset; /* 포커스시 테두리 색상 변경 */
  outline: none; /* 포커스 효과 제거 */
}

b {
  font-weight: sass.$font-weight-bold;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: sass.$black;
}
