$font-family:
  'Pretendard Variable',
  'Pretendard',
  -apple-system,
  'BlinkMacSystemFont',
  'system-ui',
  'Roboto',
  'Helvetica Neue',
  'Segoe UI',
  'Apple SD Gothic Neo',
  'Noto Sans KR',
  'Malgun Gothic',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  sans-serif;

$font-family-jp:
  'Pretendard JP Variable',
  'Pretendard JP',
  -apple-system,
  'BlinkMacSystemFont',
  'system-ui',
  'Roboto',
  'Helvetica Neue',
  'Segoe UI',
  'Apple SD Gothic Neo',
  'Noto Sans KR',
  'Malgun Gothic',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  sans-serif;

$font-family-en:
  'Neue Haas Unica Pro',
  'Pretendard Variable',
  'Pretendard',
  -apple-system,
  'BlinkMacSystemFont',
  'system-ui',
  'Roboto',
  'Helvetica Neue',
  'Segoe UI',
  'Apple SD Gothic Neo',
  'Noto Sans KR',
  'Malgun Gothic',
  'Apple Color Emoji',
  'Segoe UI Emoji',
  'Segoe UI Symbol',
  sans-serif;

$font-size-pc: 16;
$font-size-pc-px: ($font-size-pc) * 1px;
$font-size-mo: 14;
$font-size-mo-px: ($font-size-mo) * 1px;
$font-size-base: 1rem;
$font-weight-bold: 700;
$font-weight-normal: 500;
$font-weight-regular: 400;
$font-letter-spacing-base: -0.2;
$font-letter-spacing-base-px: ($font-letter-spacing-base) * 1px;

@mixin base($size, $line, $font-weight) {
  font-size: $size;
  font-style: normal;
  font-weight: $font-weight;
  line-height: $line;
}
