@use '@/ui/style/sass';

html {
  color: sass.$black;
  font-family: sass.$font-family;
  font-size: sass.$font-size-pc-px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body .body-fixed {
  position: fixed !important;
  inset: 0;
}

.lazy-load {
  transition: opacity 300ms ease-in-out;
  opacity: 0;

  &.lazy-loaded {
    opacity: 1;
  }
}

@include sass.htmlConditioner('Ciety') {
  color-scheme: dark;
}

@include sass.htmlConditioner('MarppleShop') {
  letter-spacing: sass.$font-letter-spacing-base-px;
}

@include sass.htmlConditioner('MarppleShop', false, false, 'en') {
  font-family: sass.$font-family-en;
}

@include sass.htmlConditioner(false, 'mo') {
  font-size: sass.$font-size-mo-px;
}

@include sass.htmlConditioner(false, false, false, 'jp') {
  font-family: sass.$font-family-jp;
}
